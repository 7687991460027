html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote,  pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
     margin:0;
     padding:0;
     border:0;
     outline:0;
     font-size:100%;
     vertical-align:baseline;
     background:transparent;
 }

 body {
     line-height:1;
 }

 article,aside,details,figcaption,figure, footer,header,hgroup,menu,nav,section{ 
     display:block;
 }

 nav ul {
    list-style:none;
 }

 blockquote, q {
    quotes:none;
 }

 blockquote:before, blockquote:after, q:before, q:after {
    content:'';
    content:none;
 }

 a {
    margin:0;
    padding:0;
    font-size:100%;
    vertical-align:baseline;
    background:transparent;
 }

 /* change colors to suit your needs */
 ins {
    background-color:#ff9;
    color:#000;
    text-decoration:none;
 }

 /* change colors to suit your needs */
 mark {
    background-color:#ff9;
    color:#000; 
    font-style:italic;
    font-weight:bold;
 }

 del {
    text-decoration: line-through;
 }

 abbr[title], dfn[title] {
    border-bottom:1px dotted;
    cursor:help;
 }

 table {
    border-collapse:collapse;
    border-spacing:0;
 }

 /* change border color to suit your needs */
 hr {
    display:block;
    height:1px;
    border:0;   
    border-top:1px solid #cccccc;
    margin:1em 0;
    padding:0;
 }

 input, select {
    vertical-align:middle;
 }


 /*   general */

@import "./style.scss";

@import "./colored.scss";
// @import "./dark.scss";
// @import "./light.scss";



body{
   // background-color: red;
   font-family: 'Roboto', sans-serif;
   font-size: 18px;
}

a{
   color: inherit;
   font-weight: 600;
   text-decoration:underline;
   text-decoration-color: $blue;
   &:hover{
      color: $blueDn; 
      text-decoration: underline;
      text-decoration-color: $blue;
      text-decoration-style: dotted;
   }
}


.acordeon{
   display: flex;
   flex-direction: column;
   .encabezado{

      background: $blueD;
      border-radius: .3em .3em 0 0;
      color: white;
      display: flex;
      justify-content: space-between;
      padding: .3em;
      h3{
         padding: .3em;
      }

   }
   .item{
      // display: flex;
      // justify-content: space-between;
      margin: .5em 0;
      width: 100%;
   }
   .subjectFit{
      flex-grow: 1;
      width:90% ;
   }
   .dateFit{
      margin-left: 1em;
      width: 200px;
   }
   .icoSize{
      width: 50px;
   }
   table{
      margin-top: 1.5em;
      border: 1px solid $lighten;
      width: 100%;
      tr{
         padding: .3em;
         &:nth-child(2n){
            background: $lighten;
         }
      }
      td{
         padding: .3em;
      }
   }
}

.btn{
   border: 0;
   border-radius: .3em;
   font-size: 1em;
   padding: .6em 1.2em;
}

.bigButtons{
   align-content: stretch;
   display: flex;
   flex-wrap: wrap;
   justify-content:flex-start;
   gap: .5em;

   button{
      width: 31%;
      margin: .3em;
      font-size: 1.2em;
   }
}

.big-table{
   box-sizing: border-box;
   width: 100%; 
   font-size: 0.6em; 
}

.buttons{
   display: flex;
   justify-content: flex-end;
   margin-top: 1em;
}

.btn-file{
   box-sizing: border-box;
   margin: .5em;
   max-width: 25vw;
   min-width: 200px;
   overflow: hidden;
   label{
      color: white;
      background-color: $aqua;
      border-radius: .3em;
      display: block;
      padding: 1em;
      text-shadow: 1px 1px 0 darken($aqua, 10);
      white-space: normal;

      &:hover{
         background-color: darken($aqua, 10);
      }
   }

   input[type=file]{
      display: none;
   }
}

.cols{
   display: flex;
   justify-content: space-between;
   margin-bottom: 1em;
   // flex-wrap: wrap;

   box-sizing: border-box;
   width: 100%;
   
   .col{
      box-sizing: border-box;
      
      margin: .5em;
   }
   .col1{
      width: 100%;
   }
   .col2{
      width: 50%;
   }
   .col3{
      width: 33%;
   }
   .col4{
      width: 25%;
   }
}


h1{
   font-size: 2em;
   font-weight: 700;
   padding: .5em 0;
}

.form{
   box-sizing: border-box;
   &.small{
      min-height: 100px;
      max-width: 400px;
      input{
         box-sizing: border-box;
         display: block;
         width: 100%;
      } 
      label{
         display: block;
         margin-bottom: .3em;
      }
   }
}

.form-group{
   &.small{
      max-width: 200px;
   }
}
header{
   display: block;
   padding: 1em;
   .navbar{
      display: flex;
      justify-content: space-between;

      .altMenu{
         display: none;
         
        
      }
      .navbar-nav{
         display: flex;
         justify-content: space-between;
         .nav-link{
            color: inherit;
            display: block;
            font-weight: 300;
            margin: .3em;
            padding: .5em;
            text-decoration: none;
            
            &.logged{
               text-transform: capitalize;
               &:hover{
                  border-radius: .3em;
                  
               }
            }
         }
      }
   }
   .logo{
      // background: white;
      width: 80px;
   }
}

input{
   // outline: none;
   border: none;
   // border-radius: .3em;
   font-family: 'Roboto', 'sans';
   font-size: 1.2em;
   margin-bottom: .5em;
   padding: .3em;
   width: 100%;
   &:focus{
      outline: none;
      border: none;
   }
}
 
label{
   font-weight: 300;
}

#main{
   align-items: center;
   display: flex;
   flex-direction: column;
   margin: auto;
   height: 100%;
   width: 100%;
   min-height: 100vh;
   
   &.center{
      justify-content: center;
      .panel{
         margin-top: -3em;
      }
   }
}

.form-control{
   width: 100% !important;
   
}

.MuiInput-underline:before, .MuiInput-underline:after{
   border: none !important;
}

.panel{
   border-radius: .5em;
   margin: 1em auto;
   max-width: 1200px;
   padding: 2em;
   width: 90%;

   // max-height: 70vh;
   // overflow: auto;

   &.small{
      max-width: 400px;
   }
   position: relative;
   .icons-title {
      margin: -4em auto 2em auto;
      h1{
         margin: 0;
         padding: 0;
         text-align: center;
      }
      .big-icon{
         border-radius: 50%;
         font-size: 2.4em;
         margin: auto;
         padding: .5em;
         text-align: center;
         max-width: 1.2em;
      }
   }


}

.row{
   display: flex;
   TextField{
      width: 100%;
   }
}

.scrolled{
   margin: 0;
   padding: 0;
   overflow:auto;

   max-height: 70vh;
}

.table{
   
   // margin: 2em auto;
   width: 100%;
   .icoSize{
      padding: 1px;
      text-align: center;
      width: 2em;
      .btn{
         padding: .5em;
      }
   }

   thead{
      overflow: hidden;
      text-align: left;
      th{
         vertical-align: middle;
         font-weight: 400;
         padding: .5em 1em;
         position: sticky;
         top: 0;

         background-color: $blueD;

         &:first-child{
            border-radius: .3em 0 0 0;

         }
         &:last-child{
            border-radius: 0 .3em 0 0;
         }

         &.right{
            text-align: right;
         }

         

      }
   }
   tbody{
      border: 1px solid $light;
      td{
         font-weight: 300;
         padding: .3em 1em;
         
         // a{
         //    // text-decoration: none;
         //    color: inherit;
         // }

         &.right{
            text-align: right;
         }
      }
   }

   &.simple{
      border-radius: .5em;
      overflow:hidden;
      border: 1px solid $light;
      box-shadow: 0px 0px 1px 1px $light;
      margin-top:1.5em;
      tr{
         td{
            border: 1px solid $light;
            padding: .5em;
         }
         td:first-child{
            background-color: $lighten;
            color: $dark;
         }
         td.right{
            text-align: right;
         }
         
      }
   }

   .full{
      padding: 0;
   }

   .dateFit{
      width: 20%;
   }
   .subjectFit{
      width: 70%;
   }
}


.title{
   margin: 1em 0 ;
}

h2.title{
   font-size: 1.6em;
   margin-top: 1em;
}
h3.title{
   font-size: 1.3em;
   color: $blueDn;
}
.titles{
   display: flex;
   align-items: center;
   h1{
      margin-left: .5em;
   }
   .tool-btn-agregar{
      .btn{
         padding: .5em;
      }
      
   }
   
}


.TodoApp{
   height: 100vh;
}
.toolSearch{
   // background-color: $dark;
   // border-radius: .3em 0 0 0;
   // border: 1px solid $lighten;
   margin: -1em 0 1em 0;
   padding: .3em 1em 0 1em;

   input{
      padding-left: 2em;
      width: 100% !important;
      &:focus{
         border:none;
      }
   }

   span{
      // display: block;
      margin-left: 1em -1em;
   }
   position: relative;
   .float-right{
      right: 0;
      top: 1.2em;
      position: absolute;
      font-size: 1.5em;
      button{
         padding: .3em;
      }
   }
}

@media print
{    
   
   #main{
      background-color: white;
   }


   header{
      display: none !important;
   }
    .no-print, .no-print *
    {
        display: none !important;
    }

    a{
     text-decoration: none;  
    }

    table
    {
      border: 4px solid black;
      font-size: .8em;
      max-width: 100%;
      width:100%; 
      break-inside: avoid; 
    }

    .no-break{
      break-inside: avoid; 
      page-break-inside: avoid; /* or 'auto' */
    }

   .break{

      page-break-after:always;
   }

   .salto{
      float: none !important; 
      position: static !important; 
      box-sizing: content-box !important;
      display: block !important;
      page-break-before: always !important; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
      break-before: always !important; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */

   }

   .panel{
      padding: 0;
      margin: 0;
      box-shadow: none;
   }
    
}


@import "~react-image-gallery/styles/scss/image-gallery.scss";

.grid{
   box-sizing: border-box;
   display: flex;
   flex-wrap: wrap;
}


.centered{
   justify-content: center;
}

@media screen and (max-width: 390px) { 
   body{
      font-size: 13px;
   }

   .cols{
      flex-direction: column;
      .col2,.col3,.col4{
         width: 100%;
      }
   }

   #header{
      font-size: 1.5em;
      height: 100vh;
      left: 0;
      width: 100vw;
      position: fixed;
      top: 0;
      z-index: 25412;

      display: flex;
      justify-content: center;
      align-items: center;

      nav{
         flex-direction: column;
         justify-content: space-around;
         position: relative;
         
      }
      .navbar {
         
         .altMenu{
            display: block;
            position: fixed;
            right: 1em;
            top: .5em;

         }
         

         ul{
            flex-direction: column;

            &:last-child{
               margin-top: 3em;
            }
         }
      }
   } 
   #altHeader{
      display: flex;
      height: 1.7em;
      .navbar {
         
         .altMenu{
            font-size: 2em;
            display: block;
            position: fixed;
            right: 1em;
            top: .5em;

         }
      }
      .navbar-nav{
         display: none;
      }
   }
   .bigButtons{
      box-sizing: border-box;
      button{
         box-sizing: border-box;
         width: 45%;
      }
   }
   .big-table{
      font-size: 1em;
   }
   #main{
      box-sizing: border-box;
      .panel{
         // font-size: .8em;
         box-sizing: border-box;
         padding: 1em .5em;
         min-width: 380px;
         width: 98%;
         &.small{
            max-width: 390px;
         }
      }
   }
   .form-control{
      input{
         box-sizing: border-box;
      }
   }
   #tablesContainer{
      margin: 0;
      padding: 0;
      width: 100%;

      .tableContainer{
         overflow-y: auto;
         width: 100%;
      }
   }

}