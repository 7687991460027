
// colored
$grisD: #444;
$darken : #667698;
$dark :  #6a7ca2;
$medium:#b3bcc1;
$light : #dbdfea;
$lighten: #e7ebf6;
$lightest: #eee;
$blue: #63c9ff;
$blueD:darken($blue,8);
$blueDn:darken($blue,30);
$green: #30db9f;
$yellow:#ffcb50;
$red:#fe5b51;
$aqua:#0ab5bd;



.btn{

   &.btn-success{
      background-color: $blueD;
      color: white;
      &:hover{
         background-color: $blueDn;
      }
   }

   &.btn-delete{
      background-color: $red;
      color: white;
      &:hover{
         background-color: darken($color: $red, $amount: 10);
      }
   }

   &.btn-edit{
      background-color: $yellow;
      color: brown;
      &:hover{
         background-color: darken($color: $yellow, $amount: 10);
      }
   }

   &.btn-agregar{
      background-color: $green;
      color: white;
      &:hover{
         background-color: darken($color: $green, $amount: 10);
      }
   }

   &.btn-imprimir{
      background-color: $blueDn;
      font-size: 1.6em;
      color: white;
      &:hover{
         background-color: darken($color: $blueDn, $amount: 10);
      }
   }
}

h1{
   color: $darken;
}


header{
   background: $darken;
   .navbar{
      color: $light;
      .navbar-nav{
         .nav-link{
            &:hover{
               color: $blue;
            }
            &.logged{
               .icon-user{
                  color: $blue;
               }
               &:hover{
                  color: inherit;
                  background-color: $dark;
                  text-shadow: 1px 1px 1px $darken;
                  .icon-close{
                     color: $red;

                  }
               }
            }
         }
      }
   }
}

input{
    border-bottom: 1px solid $dark !important;
    &:focus{
        border-bottom: $darken 3px solid !important;
    }
}

label{
   color: $dark;
   font-weight: 300;
}

#main{
    background-color: $light;
}

.panel{
   background-color: white;
   box-shadow: 2px 2px 14px 1px rgba(black,.1);

   position: relative;
   .icons-title {
      .big-icon{
         color: white;
         background-color: $darken;
      }
   }


}

.table{

   thead{
      background-color: $blueD;
      color: white;
      text-shadow: 1px 1px 1px $blueDn;
   }
   tbody{
      tr{
         &:nth-child(2n){
            background-color: $lightest;
         }
      }
      td{
         color: $grisD;
         
         // a{
         //    &:hover{
         //       color: $blueDn;
         //    }
         // }
      }
   }
}

.toolSearch{
   border-bottom: 1px solid $light;
   color: $light;

   input{
      &::placeholder{
         color: $light !important;
   
      }
   }

}