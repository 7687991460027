@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?vr2hbq');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?vr2hbq#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?vr2hbq') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?vr2hbq') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?vr2hbq##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add-outline {
  &:before {
    content: $icon-add-outline; 
  }
}
.icon-add-solid {
  &:before {
    content: $icon-add-solid; 
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left; 
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right; 
  }
}
.icon-arrow-thin-down {
  &:before {
    content: $icon-arrow-thin-down; 
  }
}
.icon-arrow-thin-up {
  &:before {
    content: $icon-arrow-thin-up; 
  }
}
.icon-at-symbol {
  &:before {
    content: $icon-at-symbol; 
  }
}
.icon-attachment {
  &:before {
    content: $icon-attachment; 
  }
}
.icon-block {
  &:before {
    content: $icon-block; 
  }
}
.icon-bookmark {
  &:before {
    content: $icon-bookmark; 
  }
}
.icon-buoy {
  &:before {
    content: $icon-buoy; 
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar; 
  }
}
.icon-chart-bar {
  &:before {
    content: $icon-chart-bar; 
  }
}
.icon-chart-pie {
  &:before {
    content: $icon-chart-pie; 
  }
}
.icon-chat-bubble-dots {
  &:before {
    content: $icon-chat-bubble-dots; 
  }
}
.icon-checkmark {
  &:before {
    content: $icon-checkmark; 
  }
}
.icon-cheveron-down {
  &:before {
    content: $icon-cheveron-down; 
  }
}
.icon-cheveron-up {
  &:before {
    content: $icon-cheveron-up; 
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-code {
  &:before {
    content: $icon-code; 
  }
}
.icon-cog {
  &:before {
    content: $icon-cog; 
  }
}
.icon-currency-dollar {
  &:before {
    content: $icon-currency-dollar; 
  }
}
.icon-date-add {
  &:before {
    content: $icon-date-add; 
  }
}
.icon-document {
  &:before {
    content: $icon-document; 
  }
}
.icon-document-add {
  &:before {
    content: $icon-document-add; 
  }
}
.icon-dots-horizontal-triple {
  &:before {
    content: $icon-dots-horizontal-triple; 
  }
}
.icon-download {
  &:before {
    content: $icon-download; 
  }
}
.icon-edit-pencil {
  &:before {
    content: $icon-edit-pencil; 
  }
}
.icon-exclamation-solid {
  &:before {
    content: $icon-exclamation-solid; 
  }
}
.icon-explore {
  &:before {
    content: $icon-explore; 
  }
}
.icon-factory {
  &:before {
    content: $icon-factory; 
  }
}
.icon-folder-outline {
  &:before {
    content: $icon-folder-outline; 
  }
}
.icon-folder-outline-add {
  &:before {
    content: $icon-folder-outline-add; 
  }
}
.icon-hand-stop {
  &:before {
    content: $icon-hand-stop; 
  }
}
.icon-hour-glass {
  &:before {
    content: $icon-hour-glass; 
  }
}
.icon-key {
  &:before {
    content: $icon-key; 
  }
}
.icon-link {
  &:before {
    content: $icon-link; 
  }
}
.icon-load-balancer {
  &:before {
    content: $icon-load-balancer; 
  }
}
.icon-location {
  &:before {
    content: $icon-location; 
  }
}
.icon-lock-closed {
  &:before {
    content: $icon-lock-closed; 
  }
}
.icon-lock-open {
  &:before {
    content: $icon-lock-open; 
  }
}
.icon-menu {
  &:before {
    content: $icon-menu; 
  }
}
.icon-minus-outline {
  &:before {
    content: $icon-minus-outline; 
  }
}
.icon-minus-solid {
  &:before {
    content: $icon-minus-solid; 
  }
}
.icon-navigation-more {
  &:before {
    content: $icon-navigation-more; 
  }
}
.icon-network {
  &:before {
    content: $icon-network; 
  }
}
.icon-news-paper {
  &:before {
    content: $icon-news-paper; 
  }
}
.icon-notifications-outline {
  &:before {
    content: $icon-notifications-outline; 
  }
}
.icon-phone {
  &:before {
    content: $icon-phone; 
  }
}
.icon-photo {
  &:before {
    content: $icon-photo; 
  }
}
.icon-pin {
  &:before {
    content: $icon-pin; 
  }
}
.icon-plugin {
  &:before {
    content: $icon-plugin; 
  }
}
.icon-question {
  &:before {
    content: $icon-question; 
  }
}
.icon-reload {
  &:before {
    content: $icon-reload; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}
.icon-send {
  &:before {
    content: $icon-send; 
  }
}
.icon-share-alt {
  &:before {
    content: $icon-share-alt; 
  }
}
.icon-star-full {
  &:before {
    content: $icon-star-full; 
  }
}
.icon-tag {
  &:before {
    content: $icon-tag; 
  }
}
.icon-thumbs-down {
  &:before {
    content: $icon-thumbs-down; 
  }
}
.icon-thumbs-up {
  &:before {
    content: $icon-thumbs-up; 
  }
}
.icon-time {
  &:before {
    content: $icon-time; 
  }
}
.icon-toolscopy {
  &:before {
    content: $icon-toolscopy; 
  }
}
.icon-trash {
  &:before {
    content: $icon-trash; 
  }
}
.icon-travel {
  &:before {
    content: $icon-travel; 
  }
}
.icon-tuning {
  &:before {
    content: $icon-tuning; 
  }
}
.icon-upload {
  &:before {
    content: $icon-upload; 
  }
}
.icon-user {
  &:before {
    content: $icon-user; 
  }
}
.icon-user-add {
  &:before {
    content: $icon-user-add; 
  }
}
.icon-user-group {
  &:before {
    content: $icon-user-group; 
  }
}
.icon-view-hide {
  &:before {
    content: $icon-view-hide; 
  }
}
.icon-view-show {
  &:before {
    content: $icon-view-show; 
  }
}
.icon-view-tile {
  &:before {
    content: $icon-view-tile; 
  }
}
.icon-wallet {
  &:before {
    content: $icon-wallet; 
  }
}
.icon-wrench {
  &:before {
    content: $icon-wrench; 
  }
}
.icon-zoom-in {
  &:before {
    content: $icon-zoom-in; 
  }
}
.icon-zoom-out {
  &:before {
    content: $icon-zoom-out; 
  }
}

