.thumnail{
    background: #eee;
    border-radius: .3em;
    border:1px solid #ddd;
    box-sizing: border-box;
    margin: .5em;
    padding: 1em;
    position: relative;
    width: 31%;

    img{
        width: 100%;
    }

    a.eliminar{
        background: red;
        border-radius: .3em;
        color: white;
        display: block;
        padding: .3em;
        position: absolute;
        right: 2px;
        text-decoration: none;
        top:2px;
    }

}