$icomoon-font-family: "adminsoft" !default;
$icomoon-font-path: "../fonts" !default;

$icon-add-outline: "\e900";
$icon-add-solid: "\e901";
$icon-arrow-left: "\e902";
$icon-arrow-right: "\e903";
$icon-arrow-thin-down: "\e904";
$icon-arrow-thin-up: "\e905";
$icon-at-symbol: "\e945";
$icon-attachment: "\e946";
$icon-block: "\e906";
$icon-bookmark: "\e907";
$icon-buoy: "\e908";
$icon-calendar: "\e909";
$icon-chart-bar: "\e90a";
$icon-chart-pie: "\e90b";
$icon-chat-bubble-dots: "\e90c";
$icon-checkmark: "\e90d";
$icon-cheveron-down: "\e947";
$icon-cheveron-up: "\e948";
$icon-close: "\e90e";
$icon-code: "\e90f";
$icon-cog: "\e910";
$icon-currency-dollar: "\e911";
$icon-date-add: "\e949";
$icon-document: "\e912";
$icon-document-add: "\e913";
$icon-dots-horizontal-triple: "\e914";
$icon-download: "\e915";
$icon-edit-pencil: "\e916";
$icon-exclamation-solid: "\e917";
$icon-explore: "\e918";
$icon-factory: "\e919";
$icon-folder-outline: "\e91a";
$icon-folder-outline-add: "\e91b";
$icon-hand-stop: "\e91c";
$icon-hour-glass: "\e91d";
$icon-key: "\e91e";
$icon-link: "\e91f";
$icon-load-balancer: "\e920";
$icon-location: "\e921";
$icon-lock-closed: "\e922";
$icon-lock-open: "\e923";
$icon-menu: "\e924";
$icon-minus-outline: "\e925";
$icon-minus-solid: "\e926";
$icon-navigation-more: "\e927";
$icon-network: "\e928";
$icon-news-paper: "\e929";
$icon-notifications-outline: "\e92a";
$icon-phone: "\e92b";
$icon-photo: "\e92c";
$icon-pin: "\e92d";
$icon-plugin: "\e92e";
$icon-question: "\e92f";
$icon-reload: "\e930";
$icon-search: "\e931";
$icon-send: "\e932";
$icon-share-alt: "\e933";
$icon-star-full: "\e934";
$icon-tag: "\e935";
$icon-thumbs-down: "\e936";
$icon-thumbs-up: "\e937";
$icon-time: "\e938";
$icon-toolscopy: "\e939";
$icon-trash: "\e93a";
$icon-travel: "\e93b";
$icon-tuning: "\e93c";
$icon-upload: "\e94a";
$icon-user: "\e93d";
$icon-user-add: "\e93e";
$icon-user-group: "\e93f";
$icon-view-hide: "\e940";
$icon-view-show: "\e941";
$icon-view-tile: "\e942";
$icon-wallet: "\e943";
$icon-wrench: "\e944";
$icon-zoom-in: "\e94b";
$icon-zoom-out: "\e94c";

